// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

var Flickity = require('flickity');
require('flickity-fade');
require('flickity-imagesloaded');

// Scripts
require('./modules/scripts');

document.addEventListener('DOMContentLoaded', function () {
  var AOS = require('aos');
  // https://michalsnik.github.io/aos/
  AOS.init({duration:800,easing:'slide',once:true});

});



	









